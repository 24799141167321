// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import './plugins/gtranslate';
import Foundation from './autoload/foundation';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Review page
  if ($('.review-page').length) {
    let form = $('.review-page__form'),
      social = $('.review-page__social'),
      socialItems = social.find('.socials-wrapper'),
      main = $('.review-page__main'),
      stars = main.find('.stars-wrapper'),
      close = $('.close-button');
    close.on('click', function () {
      $(this).closest('section').hide();
      main.show();
    });
    stars.on('click', function () {
      stars.removeClass('is-active');
      $(this).addClass('is-active').prevAll().addClass('is-active');
      if ($(this).data('number') > 3) {
        social.show();
      } else {
        form.show();
        main.hide();
      }
    });
    stars.mouseover(function () {
      $(this).addClass('is-active').prevAll().addClass('is-active');
      $(this).nextAll().removeClass('is-active');
    });

    if (socialItems.hasClass('a-lot')) {
      socialItems.on('click', function () {
        $(this).toggleClass('is-active');
        $(this).siblings('.socials-wrapper--popup').slideToggle();
        // $(this).closest('.socials-wrapper--popup').toggle();
      });
    }

    $('.close-awesome').on('click', (e) =>
      $(e.currentTarget).parent().slideUp()
    );
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', function () {
    removeFieldPlaceholder();
    initSelectMenu();
    initGfieldLabel();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

$('.testimonials-slider').each((idx, elem) => {
  let arrowsWrap = $(elem).prevAll('.slick-arrows-wrap');
  let slidesCount = $(elem).find('.testimonials-slider__item').length;

  $(elem).slick({
    slidesToShow: slidesCount < 4 ? slidesCount : 3,
    rows: 0,
    arrows: false,
    dots: true,
    infinite: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesCount < 3 ? slidesCount : 2,
          dots: false,
          arrows: true,
          appendArrows: arrowsWrap,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: true,
          appendArrows: arrowsWrap,
        },
      },
    ],
  });
});

$('blockquote').prepend('<span class="icon-quote"></span>');

function initGfieldLabel() {
  let gfieldInput = $('.gfield').find('input');

  gfieldInput.each((i, el) => {
    let $label = $(el).closest('.gfield').find('.gfield_label');
    if ($(el).val()) {
      $label.addClass('active');
    }
  });

  gfieldInput.on('focusin', function () {
    let $label = $(this).closest('.gfield').find('.gfield_label');
    $label.addClass('active');
  });

  gfieldInput.on('focusout', function () {
    let $label = $(this).closest('.gfield').find('.gfield_label');
    if (!$(this).val()) {
      $label.removeClass('active');
    }
  });
}

function initSelectMenu() {
  $('.gfield_select').each((idx, el) => {
    let $container = $(el).closest('.ginput_container_select');
    let $label = $(el).closest('.gfield').find('.gfield_label');
    let labelVal = $label.text();
    if ($container.hasClass('initilized')) return;

    if ($(el).parents('.contact-form').length) {
      labelVal = 'Select one';
    } else {
      $label.hide();
    }
    $(el).myselectmenu({
      appendTo: $container,
      placeholder: labelVal,
      change: function () {
        $(this).trigger('change');
      },
      create: function () {
        $container.addClass('initilized');
      },
    });
  });
}

$.widget('custom.myselectmenu', $.ui.selectmenu, {
  _drawButton: function () {
    this._super();
    var selected = this.element.find('[selected]').length,
      placeholder = this.options.placeholder;

    if (!selected && placeholder) {
      this.buttonItem.text(placeholder);
    }
  },
});

$('.partners-slider').slick({
  slidesToShow: 7,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 2000,
  rows: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '80px',
      },
    },
  ],
});

$('.trailer').each((idx, el) => {
  let titleHeight = $(el).find('.trailer__title').height() + 60;
  $(el).find('.trailer__content').css('height', `calc(0% + ${titleHeight}px)`);
});

if ($('.dealer').length) {
  $('.dealer').each((idx, dealer) => {
    let state = $(dealer).data('state').toUpperCase();
    let content = $(dealer).html();

    $('#' + state)
      .addClass('hasPopup')
      .on('click', null, { html: content }, showPopup);
  });

  $(document).on('click', function (e) {
    if (
      !$(e.target).closest('.popup').length &&
      !$(e.target).hasClass('hasPopup')
    ) {
      $('.popup').remove();
      $('.land').removeClass('popupActive');
    }
  });
}

function showPopup(e) {
  if ($('.popup').length) {
    $('.popup').remove();
    $('.land').removeClass('popupActive');
  }

  let container = $('.find-dealer__map');
  let containerTop = container.offset().top - $(window).scrollTop();
  let containerLeft = container.offset().left;

  let popup = $('<div>').addClass('popup');
  let top = e.clientY - containerTop;
  let left = e.clientX - containerLeft;
  popup.html(e.data.html);
  popup.css('position', 'absolute');
  popup.css('top', top + 'px');
  popup.css('left', left + 'px');
  $('.find-dealer__map').append(popup);
  $(this).addClass('popupActive');
}

function initSliderForMobile(selector) {
  let isInitialized = false;
  let slider;
  let arrowsWrap;
  $(window).on('load resize', () => {
    if (Foundation.MediaQuery.is('small down')) {
      if (!isInitialized) {
        arrowsWrap = $('<div class="slick-arrows-wrap"></div>').insertBefore(
          selector
        );

        slider = $(selector).slick({
          slidesToShow: 1,
          rows: 0,
          appendArrows: arrowsWrap,
        });
        isInitialized = true;
      }
    } else if (isInitialized) {
      slider.slick('destroy');
      arrowsWrap.remove();
      isInitialized = false;
    }
  });
}

['.fxy-gallery__inner', '.featured-posts__inner'].forEach((selector) => {
  initSliderForMobile(selector);
});

$('.trailer-downloads__item').each((idx, elem) => {
  let res = elem.innerText
    .split(' ')
    .map((item, i) => {
      if (i === 1) {
        return item + '\n';
      }

      return item;
    })
    .join(' ');

  elem.innerText = res;
});

$(document).on('click', (e) => {
  const $target = $(e.target);
  if ($target.hasClass('.menu-icon') || $target.parents('.menu-icon').length)
    return;

  if ($('.menu-icon').hasClass('is-active')) {
    if (!$target.parents('.top-bar').length || $target.hasClass('top-bar')) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }
});
