$('.switcher .selected').click(function () {
  $('.switcher .option a img').each(function () {
    if (!$(this)[0].hasAttribute('src'))
      $(this).attr('src', $(this).attr('data-gt-lazy-src'));
  });
  if (!$('.switcher .option').is(':visible')) {
    $('.switcher .option').stop(true, true).delay(100).slideDown(500);
    $('.switcher .selected a').toggleClass('open');
  }
});
$('.switcher .option').bind('mousewheel', function (e) {
  var options = $('.switcher .option');
  if (options.is(':visible'))
    options.scrollTop(options.scrollTop() - e.originalEvent.wheelDelta / 10);
  return false;
});
$('body')
  .not('.switcher')
  .click(function (e) {
    if (
      $('.switcher .option').is(':visible') &&
      e.target != $('.switcher .option').get(0)
    ) {
      $('.switcher .option').stop(true, true).delay(100).slideUp(500);
      $('.switcher .selected a').toggleClass('open');
    }
  });
